'use client';

import { ComponentProps } from 'react';

import { useGlobalStore } from '~/stores/globalStore';
import { cx } from '~/utils';

import styles from './SecondaryHeaderWrapper.module.scss';

interface SecondaryHeaderWrapperProps extends ComponentProps<'div'> {
  heroText?: string;
  isModalEnabledOnSite?: boolean;
  link?: string;
}
/**
 * This client component manages all the listeners and states for the header
 */
export const SecondaryHeaderWrapper = (props: SecondaryHeaderWrapperProps) => {
  const { className, children, heroText, isModalEnabledOnSite, link, ...otherProps } = props;

  const { headerTheme, headerReady, scrollingUp, atPageTop, scrollDisabled } = useGlobalStore([
    'headerTheme',
    'headerReady',
    'scrollingUp',
    'atPageTop',
    'scrollDisabled',
    'activeNavItem',
    'dropdownOpen',
  ]);

  return (
    <>
      <div
        {...otherProps}
        className={cx(
          styles.wrapper,
          scrollingUp && styles.scrollingUp,
          !atPageTop && styles.notAtPageTop,
          !headerReady && styles.headerNotReady,
          scrollDisabled && styles.scrollDisabled,
          className
        )}
        id="secondary-header"
        data-theme={headerTheme}
      >
        {children}
      </div>
    </>
  );
};
