'use client';

import { usePathname } from 'next/navigation';
import { ComponentProps, useEffect, useState } from 'react';

import {
  Asset,
  Link,
  Maybe,
  NavigationCollection,
  NavigationFragment,
  ProductNavigationLink,
} from '~/cms';
import { getHasSeen, setTimeSeen } from '~/components/exo-iris';
import { CartIcon } from '~/components/svgs/CartIcon';
import { ExoLink } from '~/components/ui/ExoLink';
import { Logo } from '~/components/ui/Logo';
import { cx } from '~/utils';

import styles from './Header.module.scss';
import { HeaderWrapper } from './HeaderWrapper';
import { Nav } from './Nav';
import { NavItem } from './NavItem';

interface HeaderProps extends ComponentProps<'header'> {
  className?: string;
  home: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  productsCollection: Maybe<{
    items: Pick<ProductNavigationLink, 'label' | 'color' | 'url'>[];
  }>;
  support: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  resources: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  technologyLink: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  aboutUsCollection: Maybe<{ items: Pick<Link, 'label' | 'href' | 'external'>[] }>;

  store: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  product: Maybe<Pick<Link, 'label' | 'href' | 'external'>>;
  heroText?: string;
  isModalEnabledOnSite?: boolean;
  link?: string;
}

export const Header = (props: HeaderProps) => {
  const {
    home,
    productsCollection,
    support,
    resources,
    technologyLink,
    aboutUsCollection,
    className,
    store,
    product,
    heroText,
    isModalEnabledOnSite,
    link,
    ...otherProps
  } = props;

  const LOCAL_STORAGE_KEY = 'advertisement_banner_last_seen';
  const DAY_MS = 86400000;
  const TIME_DIFFERENCE = DAY_MS; // 1 day
  const [advtBanner, SetAdvtBanner] = useState(true);

  const aboutUsItems = aboutUsCollection?.items.map((item, index) => {
    return {
      label: item.label,
      href: item.href,
    };
  });

  const productsItems = productsCollection?.items.map((item, index) => {
    return {
      label: item.label,
      href: item.url,
      color: item.color,
    };
  });

  const getLocalStorageKey = () => {
    return `${LOCAL_STORAGE_KEY}`;
  };
  const pathname = usePathname();

  useEffect(() => {
    if (!getHasSeen(getLocalStorageKey(), TIME_DIFFERENCE)) {
      const header = document.getElementById('secondary_header');
      if (header) {
        header.style.marginTop = '63rem';
      }
      SetAdvtBanner(true);
    } else {
      SetAdvtBanner(false);
    }
  }, [pathname, TIME_DIFFERENCE]);

  const handleCloseButton = () => {
    setTimeSeen(getLocalStorageKey());
    SetAdvtBanner(false);
    const header = document.getElementById('secondary_header');
    if (header) {
      header.style.marginTop = '0rem';
    }
  };

  return (
    <>
      <HeaderWrapper heroText={heroText} isModalEnabledOnSite={isModalEnabledOnSite} link={link}>
        {advtBanner && isModalEnabledOnSite && (
          <a href={link}>
            <div className={cx(styles.wellstar)}>
              <p>{heroText}</p>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleCloseButton();
                }}
                className={styles.closeButton}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 51 50"
                  fill="none"
                  className={styles.closeIcon}
                >
                  <path
                    d="M18.4844 18.4844L32.0014 32.0014"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M18.4844 32L32.0014 18.4829"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <rect
                    x="1"
                    y="1"
                    width="48"
                    height="48"
                    rx="24"
                    stroke="currentColor"
                    strokeWidth="2"
                  />
                </svg>
              </button>
            </div>
          </a>
        )}
        <header className={cx(styles.header, className)} {...otherProps}>
          <ExoLink href="/" className={styles.logoLink}>
            <Logo hideIconUntil="lg" className={styles.logo} />
          </ExoLink>
          <Nav>
            <NavItem href={home?.href ?? '/'} id="home">
              {home?.label}
            </NavItem>
            <NavItem id="products" subItems={productsItems as any}>
              Products
            </NavItem>
            <NavItem id="technology" href={technologyLink?.href ?? ''}>
              {technologyLink?.label}
            </NavItem>
            <NavItem id="resources" href={resources?.href ?? ''}>
              {resources?.label}
            </NavItem>
            <NavItem
              id="support"
              isExternal={support?.external ?? undefined}
              href={support?.href ?? ''}
            >
              {support?.label}
            </NavItem>
            <NavItem id="about-us" subItems={aboutUsItems as any}>
              About Us
            </NavItem>
            <NavItem id="store" isExternal={store?.external ?? undefined} href={store?.href ?? ''}>
              {store?.label}
            </NavItem>
            <NavItem
              id="cart"
              isExternal={product?.external ?? undefined}
              href={product?.href ?? ''}
              className={`${styles.mobiNav} ${styles.cartIcon}`}
            >
              <CartIcon />
            </NavItem>
          </Nav>
        </header>
      </HeaderWrapper>
    </>
  );
};
